<template>
  <div class="cbo-wrapper wrapper--md-info">
    <div class="cbo-product">
      <Container class="product-top" white noRadius>
        <div class="top-inner">
          <div class="top-slider">
            <carousel
              :per-page="1"
              :pagination-enabled="false"
              :value="indexSlider"
              @page-change="changeIndexThumb"
            >
              <slide
                v-for="(media, index) in product.mediacollection"
                :key="'media_' + index"
              >
                <div class="slider-el">
                  <div class="el-picture">
                    <Video
                      v-if="media.url.substring(media.url.length - 3) === 'mp4'"
                      :src="media.url"
                    />
                    <img
                      v-else
                      :src="media.url"
                      alt=""
                    />
                  </div>
                </div>
              </slide>
            </carousel>

            <div class="slider-thumbs hidden-sm-and-down">
              <carousel
                :per-page="4"
                :pagination-enabled="false"
                :value="indexThumb"
              >
                <slide
                  v-for="(media, index) in product.mediacollection"
                  :key="'media_' + index"
                  :data-index="index"
                  @slideclick="changeIndexSlider"
                >
                  <div
                    :class="[
                      { 'slider-thumb': true },
                      { active: indexThumb === index }
                    ]"
                  >
                    <div class="el-picture">
                      <div
                        v-if="
                          media.url.substring(media.url.length - 3) === 'mp4'
                        "
                        class="video"
                      >
                        <img src="../assets/images/play.png" alt="" />
                      </div>
                      <img v-else :src="media.url" alt="" />
                    </div>
                  </div>
                </slide>
              </carousel>
            </div>
          </div>

          <div class="top-content cbo-cms">
            <div
              v-if="product.subtitle"
              class="content-subtitle cbo-title-3"
            >
              {{ product.subtitle }} oleeeeeee
            </div>
            <div v-if="product.title" class="product-title cbo-title-1">
              {{ product.title }}
            </div>
            <div
              v-if="product.accroche"
              v-html="product.accroche"
              class="content-chapo chapo"
            ></div>
            <div
              v-if="product.texteIntro"
              v-html="product.texteIntro"
              class="content-desc"
            ></div>
            <div v-if="product.documentation" class="content-button">
              <Button
                :href="product.documentation"
                target="_blank"
                color="accent"
              >
                {{ $strings.productsheet_documentation_button }}
              </Button>
            </div>
          </div>
        </div>
      </Container>

      <div v-if="product.video" class="product-video">
        <div class="video-player">
          <Video
            :src="product.video.url"
          />
        </div>
      </div>

      <Container class="product-tabs" white noRadius small>
        <div class="tabs-inner">
          <div class="tabs-nav">
            <v-select
              :items="[
                { text: $strings.productsheet_title_advantages, value: 0 },
                { text: $strings.productsheet_title_domains, value: 1 },
                { text: $strings.productsheet_title_informations, value: 2 }
              ]"
              v-model="tab"
              class="nav-select"
              color="primary"
            ></v-select>
            <button
              type="button"
              v-if="product.avantage_media || product.avantages"
              :class="[{ active: tab === 0 }]"
              @click="tab = 0"
            >
              {{ $strings.productsheet_title_advantages }}
            </button>
            <button
              type="button"
              v-if="markets.length > 0"
              :class="[{ active: tab === 1 }]"
              @click="tab = 1"
            >
              {{ $strings.productsheet_title_domains }}
            </button>
            <button
              type="button"
              v-if="product.caract_tech"
              :class="[{ active: tab === 2 }]"
              @click="tab = 2"
            >
              {{ $strings.productsheet_title_informations }}
            </button>
            <button
              type="button"
              v-if="models && models.length > 0"
              :class="[{ active: tab === 3 }]"
              @click="tab = 3"
            >
              {{ $strings.productsheet_title_models }}
            </button>
          </div>

          <div class="tabs-advantages" v-if="tab === 0">
            <div
              class="advantages-video"
              v-if="product.avantage_media"
              :key="videoKey"
            >
              <div class="video-player">
                <Video
                  v-if="
                    product.avantage_media.file.substring(
                      product.avantage_media.file.length - 3
                    ) === 'mp4'
                  "
                  :src="product.avantage_media.file"
                />
                <img v-else :src="product.avantage_media.file" alt="" />
              </div>
            </div>
            <div class="advantages-content cbo-cms">
              <div v-html="product.avantages"></div>
            </div>
          </div>

          <div class="tabs-domains" v-if="tab === 1">
            <carousel
              class="domains-slider"
              centerMode
              :per-page-custom="[
                [0, 1],
                [768, 2],
                [1024, 4]
              ]"
              :pagination-enabled="false"
            >
              <slide
                v-for="(market, index) in markets"
                :key="'market_' + index"
              >
                <div class="slider-el">
                  <div class="el-icon">
                    <img :src="market.logo" alt="" />
                  </div>
                  <div class="el-title">
                    {{ market.name }}
                  </div>
                  <div class="el-button">
                    <Button
                      color="accent"
                      :to="{ name: 'processes', params: { market: market.id } }"
                    >
                      {{ $strings.productsheet_domain_button_label }}
                    </Button>
                  </div>
                </div>
              </slide>
            </carousel>
          </div>

          <div
            class="tabs-informations"
            v-if="tab === 2"
            v-html="product.caract_tech"
          ></div>

          <div class="tabs-models" v-if="tab === 3">
            <v-row v-if="models.length > 0" class="models-list">
              <v-col>
                <div v-for="(model, index) in models" :key="'model_' + index">
                  <div
                    v-if="index % 2 === 0"
                    :class="[
                      'list-el',
                      { 'el--active': activeModels.indexOf(index) !== -1 }
                    ]"
                  >
                    <button
                      type="button"
                      class="el-title"
                      @click="switchModel(index)"
                    >
                      {{ model.titre }}
                      <div class="title-icon">
                        <v-icon color="white">
                          $vuetify.icons.values.angle
                        </v-icon>
                      </div>
                    </button>
                    <div class="el-inner">
                      <div
                        v-if="model.image"
                        class="el-picture cbo-picture-cover"
                      >
                        <img :src="model.image.file" alt="" />
                      </div>
                      <div
                        class="el-description cbo-cms"
                        v-html="model.description"
                      ></div>
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col>
                <div v-for="(model, index) in models" :key="'model_' + index">
                  <div
                    v-if="index % 2 !== 0"
                    :class="[
                      'list-el',
                      { 'el--active': activeModels.indexOf(index) !== -1 }
                    ]"
                  >
                    <button
                      type="button"
                      class="el-title"
                      @click="switchModel(index)"
                    >
                      {{ model.titre }}
                      <div class="title-icon">
                        <v-icon color="white">
                          $vuetify.icons.values.angle
                        </v-icon>
                      </div>
                    </button>
                    <div class="el-inner">
                      <div
                        v-if="model.image"
                        class="el-picture cbo-picture-cover"
                      >
                        <img :src="model.image.file" alt="" />
                      </div>
                      <div
                        class="el-description cbo-cms"
                        v-html="model.description"
                      ></div>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
      </Container>

      <Container class="product-project" primary noRadius>
        <div class="project-inner">
          <div
            class="project-title"
            v-html="$strings.productsheet_project_title"
          ></div>
          <div class="project-button">
            <Button
              :href="$strings.productsheet_project_button_href"
              target="_blank"
            >
              {{ $strings.productsheet_project_button_label }}
            </Button>
          </div>
        </div>
      </Container>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Container from "@/components/Container";
import Button from "@/components/Button";
import Video from "@/components/Video";

export default {
  name: "Product",
  components: {
    Container,
    Button,
    Video
  },
  data: () => ({
    tab: 0,
    markets: [],
    models: [],
    activeModels: [],
    indexSlider: 0,
    indexThumb: 0,
    videoKey: "pending"
  }),
  computed: {
    ...mapGetters({
      products: "products/items",
      product: "products/item",
      videos: "videos/selection"
    })
  },
  watch: {
    products: {
      handler: function() {
        this.$store.dispatch("products/find", this.$route.params.product);
      },
      immediate: true
    },
    product: {
      handler: function() {
        if (this.product.id) {
          this.$store.dispatch("videos/selectByProductID", this.product.id);
        }

        if (this.product.domaines_application) {
          this.product.domaines_application.forEach((domain) => {
            this.$store
              .dispatch("markets/find", parseInt(domain))
              .then((item) => {
                if (item) this.markets.push(item);
              });
          });
        }

        if (this.product.avantage_gamme) {
          this.product.avantage_gamme.forEach((model) => {
            if (model.language === this.$lang) this.models.push(model);
          });
        }

        this.updateIndexTab();
      },
      immediate: true
    },
    markets: {
      handler: function() {
        this.updateIndexTab();
      },
      immediate: true
    },
    videos: {
      handler: function() {
        if (this.videos.id) this.videoKey = "loaded";
      },
      immediate: true
    }
  },
  methods: {
    changeIndexSlider(slide) {
      const index = parseInt(slide.index);
      this.indexSlider = index;
      this.indexThumb = index;
    },

    changeIndexThumb(slide) {
      const index = parseInt(slide);
      this.indexSlider = index;
      this.indexThumb = index;
    },

    updateIndexTab() {
      if (!this.product.avantages && !this.product.avantage_media) {
        if (this.markets.length > 0) this.tab = 1;
        else if (this.product.caract_tech) this.tab = 2;
        else if (this.product.avantage_gamme) this.tab = 3;
      } else {
        this.tab = 0;
      }
    },

    getVideoByID(id) {
      let result = null;
      if (id) {
        this.videos.forEach((video) => {
          if (video.id === id) {
            result = video.file;
          }
        });
      }
      return result;
    },

    switchModel(index) {
      const indexof = this.activeModels.indexOf(index);
      if (indexof === -1) this.activeModels.push(index);
      else this.activeModels.splice(indexof, 1);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~vuetify/src/styles/settings/_variables";

.cbo-product {
  padding-bottom: 8rem;

  .product-top {
    .top-inner {
      position: relative;
      padding-bottom: 4rem;

      &:after {
        content: "";
        display: block;
        width: 3.56rem;
        height: 3.56rem;
        background: url(../assets/images/plus-orange-blue.svg) no-repeat center;
        background-size: contain;
        position: absolute;
        bottom: 1.5rem;
        right: 0;
      }
    }

    .top-slider {
      margin-top: -12px;
      margin-left: -2rem;
      margin-right: -2rem;

      .slider-thumbs {
        margin: 0 -0.325rem;
      }

      .slider-el,
      .slider-thumb {
        .el-picture {
          position: relative;
          width: 100%;
          padding-bottom: 70%;

          img,
          .cbo-videoplayer,
          .video {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }

          img {
            object-fit: cover;
          }
        }
      }

      .slider-thumb {
        padding: 0.75rem 0.325rem 0 0.325rem;

        .el-picture {
          padding-bottom: 59%;
          cursor: pointer;
          transition: all 0.5s;
          overflow: hidden;

          img {
            z-index: -1;
          }

          .video {
            display: flex;
            align-items: center;
            justify-content: center;
            background: var(--v-primary-base);
            z-index: -1;

            img {
              position: static;
              width: 3rem;
              height: auto;
              z-index: 1;
            }
          }
        }

        &.active .el-picture {
          box-shadow: 0 0 0 4px var(--v-accent-base) inset;
          border-radius: 0.325rem;
        }
      }
    }

    .top-content {
      padding: 1.5rem 0;
    }
  }

  .product-video {
    .video-player {
      position: relative;
      padding-bottom: 58%;

      .cbo-videoplayer {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
      }
    }
  }

  .product-tabs {
    .tabs-nav {
      margin: 1rem 0;

      .nav-select {
        text-transform: uppercase;
        font-size: 0.94rem;
        letter-spacing: 0.12em;
        font-weight: 700;
        border-bottom-width: 1px !important;

        &::v-deep {
          .v-input__slot {
            &:before {
              bottom: 0;
              background-color: var(--v-primary-base);
            }

            &:after {
              content: "";
              display: none;
            }
          }

          .v-select__selection {
            color: var(--v-primary-base);
            border-bottom: 6px solid var(--v-accent-base);
            height: 100%;
            margin-top: 0;
            margin-bottom: 0;
            padding-top: 7px;
            padding-bottom: 7px;
          }
        }
      }

      button {
        display: none;
      }
    }

    .tabs-advantages {
      .advantages-video {
        margin-bottom: 1.5rem;

        .video-player {
          position: relative;
          padding-bottom: 71%;

          .cbo-videoplayer,
          img {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            object-fit: cover;
          }
        }
      }
    }

    .tabs-domains {
      padding-right: 20%;
      padding-left: 20%;
      padding-bottom: 3rem;

      .domains-slider {
        overflow: visible !important;

        &::v-deep {
          .VueCarousel-wrapper,
          .v-responsive {
            overflow: visible !important;
          }
        }

        .slider-el {
          box-shadow: 0 0 30px rgba(0, 0, 0, 0.11);
          background-color: white;
          padding: 2rem 1.5rem;
          text-align: center;
          margin: 0 0.75rem;

          .el-icon {
            margin: 0 auto;
            width: 4.38rem;
            height: 4.38rem;
            position: relative;

            img {
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              object-fit: contain;
            }
          }

          .el-title {
            font-size: 0.94em;
            color: var(--v-primary-base);
            font-weight: 700;
            margin-bottom: 2rem;
          }
        }
      }
    }

    .tabs-informations {
      overflow: auto;
      padding: 0 0 2rem 0;

      &::v-deep p {
        font-size: 0.69rem;
        margin: 0.5rem 0;
      }

      &::v-deep table {
        width: 100% !important;
        table-layout: auto !important;
        border: none !important;
        border-collapse: collapse !important;

        td,
        th {
          width: auto !important;
          font-size: 0.69rem;
          font-weight: 700;
          color: var(--v-primary-base);
          text-align: center;
          padding: 0.5rem !important;
          margin: 0 !important;
          border: none;
          border-right: 1px solid var(--v-primary-base);
          vertical-align: middle;

          p {
            margin: 0;
          }
        }

        tr {
          padding: 0 !important;
          margin: 0 !important;

          td {
            background: white;

            &:last-child {
              border: none;
            }
          }

          &:nth-child(even) td {
            background: #e3f4fc;
          }

          .tg-uzvj,
          th {
            background: var(--v-primary-base);
            color: white;
            text-align: left;
            border: none;
            width: 30% !important;

            &[rowspan="2"],
            &[rowspan="3"],
            &[rowspan="4"],
            &[rowspan="5"] {
              background: var(--v-primary-base) !important;
            }
          }

          &:nth-child(even) {
            .tg-uzvj,
            th {
              background: var(--v-accent-base);
            }
          }
        }

        thead {
          .tg-uzvj,
          th {
            background: var(--v-secondary-base);
            border-color: white;
            text-align: center;
          }
        }
      }
    }

    .tabs-models {
      .models-list {
        .list-el {
          margin: 0 0 1.5rem 0;
          padding-bottom: 0;
          border-bottom: 1px solid var(--v-primary-base);

          .el-title {
            text-transform: uppercase;
            font-weight: bold;
            font-size: 1rem;
            letter-spacing: 0.05em;
            text-decoration: none;
            margin: 0;
            line-height: 1;
            background: none;
            border: none;
            position: relative;
            padding: 0 2.5rem 0 0;
            color: var(--v-primary-base);
            width: 100%;
            display: block;
            text-align: left;
            margin: 0 0 1rem 0;

            .title-icon {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 1.31rem;
              height: 1.31rem;
              position: absolute;
              top: -0.15rem;
              right: 0rem;
              background: var(--v-accent-base);
              border-radius: 50%;
              transition: transform 0.2s ease-in-out;

              .v-icon {
                font-size: 0.75rem;
                width: 0.75rem;
                height: 0.75rem;
                transform: rotate(90deg);
                margin-top: 0.1rem;
              }
            }
          }

          .el-picture {
            width: 100%;
            padding-bottom: 53%;
            position: relative;
          }

          .el-inner {
            position: relative;
            overflow: hidden;
            width: 100%;
            max-height: 0;
            transition: max-height 0.5s ease-in-out;
          }

          &.el--active {
            .el-title {
              .title-icon {
                transform: rotate(-180deg);
              }
            }
            .el-inner {
              max-height: 31.25rem;
            }
          }
        }
      }
    }
  }

  .product-project {
    background: var(--v-primary-base);
    text-align: center;

    .project-inner {
      position: relative;
      padding: 5.94rem 0;

      &:after {
        content: "";
        display: block;
        position: absolute;
        width: 10.19rem;
        height: 17.81rem;
        background: url(../assets/images/lines.svg) no-repeat center;
        left: -4rem;
        bottom: -13rem;
      }
    }

    .project-title {
      font-size: 1.62rem;
      text-transform: uppercase;
      line-height: 1.31em;
      letter-spacing: 0.15em;
      color: white;

      strong {
        display: block;
        font-weight: 900;
      }
    }

    .project-button {
      margin-top: 1.5rem;
    }
  }
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .cbo-product {
    .product-top {
      .top-inner {
        display: flex;
        justify-content: space-between;
        padding-bottom: 5rem;
      }

      .top-slider {
        width: 60%;
      }

      .top-content {
        width: 40%;
        padding-left: 4.38rem;
      }
    }

    .product-video {
      .video-player {
        padding-bottom: 26%;
      }
    }

    .product-tabs {
      padding-bottom: 4rem;

      .tabs-nav {
        display: flex;
        justify-content: space-around;
        padding: 2rem 0 0 0;
        margin-bottom: 3rem;
        border-bottom: 1px solid var(--v-primary-base);

        .nav-select {
          display: none;
        }

        button {
          display: inline-block;
          background: none;
          border: none;
          border-bottom: 6px solid transparent;
          font-size: 0.94rem;
          color: var(--v-primary-base);
          letter-spacing: 0.15em;
          text-transform: uppercase;
          padding: 0 0 0.5rem 0;

          &.active {
            font-weight: 700;
            border-color: var(--v-accent-base);
          }
        }
      }

      .tabs-advantages {
        display: flex;

        .advantages-video {
          width: 100%;
          margin: 0;
        }

        .advantages-content {
          width: 100%;
          padding-left: 4.5rem;
        }
      }

      .tabs-domains {
        padding-right: 0;
        padding-left: 0;
      }

      .tabs-models {
        .models-list {
        }
      }
    }

    .product-project {
      .project-inner {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 3.5rem 0;

        &:before {
          content: "";
          display: block;
          width: 9.44rem;
          height: 9.12rem;
          background: url(../assets/images/plus-multiple.svg) no-repeat;
          background-size: contain;
          position: absolute;
          top: 1.81rem;
          right: 0;
        }

        &:after {
          bottom: -11rem;
          left: -2rem;
        }
      }

      .project-title {
        font-size: 1.62rem;

        strong {
          display: inline;
        }
      }

      .project-button {
        margin: 0 0 0 4rem;
      }
    }
  }
}
</style>
