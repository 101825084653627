var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cbo-wrapper wrapper--md-info"},[_c('div',{staticClass:"cbo-product"},[_c('Container',{staticClass:"product-top",attrs:{"white":"","noRadius":""}},[_c('div',{staticClass:"top-inner"},[_c('div',{staticClass:"top-slider"},[_c('carousel',{attrs:{"per-page":1,"pagination-enabled":false,"value":_vm.indexSlider},on:{"page-change":_vm.changeIndexThumb}},_vm._l((_vm.product.mediacollection),function(media,index){return _c('slide',{key:'media_' + index},[_c('div',{staticClass:"slider-el"},[_c('div',{staticClass:"el-picture"},[(media.url.substring(media.url.length - 3) === 'mp4')?_c('Video',{attrs:{"src":media.url}}):_c('img',{attrs:{"src":media.url,"alt":""}})],1)])])}),1),_c('div',{staticClass:"slider-thumbs hidden-sm-and-down"},[_c('carousel',{attrs:{"per-page":4,"pagination-enabled":false,"value":_vm.indexThumb}},_vm._l((_vm.product.mediacollection),function(media,index){return _c('slide',{key:'media_' + index,attrs:{"data-index":index},on:{"slideclick":_vm.changeIndexSlider}},[_c('div',{class:[
                    { 'slider-thumb': true },
                    { active: _vm.indexThumb === index }
                  ]},[_c('div',{staticClass:"el-picture"},[(
                        media.url.substring(media.url.length - 3) === 'mp4'
                      )?_c('div',{staticClass:"video"},[_c('img',{attrs:{"src":require("../assets/images/play.png"),"alt":""}})]):_c('img',{attrs:{"src":media.url,"alt":""}})])])])}),1)],1)],1),_c('div',{staticClass:"top-content cbo-cms"},[(_vm.product.subtitle)?_c('div',{staticClass:"content-subtitle cbo-title-3"},[_vm._v(" "+_vm._s(_vm.product.subtitle)+" oleeeeeee ")]):_vm._e(),(_vm.product.title)?_c('div',{staticClass:"product-title cbo-title-1"},[_vm._v(" "+_vm._s(_vm.product.title)+" ")]):_vm._e(),(_vm.product.accroche)?_c('div',{staticClass:"content-chapo chapo",domProps:{"innerHTML":_vm._s(_vm.product.accroche)}}):_vm._e(),(_vm.product.texteIntro)?_c('div',{staticClass:"content-desc",domProps:{"innerHTML":_vm._s(_vm.product.texteIntro)}}):_vm._e(),(_vm.product.documentation)?_c('div',{staticClass:"content-button"},[_c('Button',{attrs:{"href":_vm.product.documentation,"target":"_blank","color":"accent"}},[_vm._v(" "+_vm._s(_vm.$strings.productsheet_documentation_button)+" ")])],1):_vm._e()])])]),(_vm.product.video)?_c('div',{staticClass:"product-video"},[_c('div',{staticClass:"video-player"},[_c('Video',{attrs:{"src":_vm.product.video.url}})],1)]):_vm._e(),_c('Container',{staticClass:"product-tabs",attrs:{"white":"","noRadius":"","small":""}},[_c('div',{staticClass:"tabs-inner"},[_c('div',{staticClass:"tabs-nav"},[_c('v-select',{staticClass:"nav-select",attrs:{"items":[
              { text: _vm.$strings.productsheet_title_advantages, value: 0 },
              { text: _vm.$strings.productsheet_title_domains, value: 1 },
              { text: _vm.$strings.productsheet_title_informations, value: 2 }
            ],"color":"primary"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}}),(_vm.product.avantage_media || _vm.product.avantages)?_c('button',{class:[{ active: _vm.tab === 0 }],attrs:{"type":"button"},on:{"click":function($event){_vm.tab = 0}}},[_vm._v(" "+_vm._s(_vm.$strings.productsheet_title_advantages)+" ")]):_vm._e(),(_vm.markets.length > 0)?_c('button',{class:[{ active: _vm.tab === 1 }],attrs:{"type":"button"},on:{"click":function($event){_vm.tab = 1}}},[_vm._v(" "+_vm._s(_vm.$strings.productsheet_title_domains)+" ")]):_vm._e(),(_vm.product.caract_tech)?_c('button',{class:[{ active: _vm.tab === 2 }],attrs:{"type":"button"},on:{"click":function($event){_vm.tab = 2}}},[_vm._v(" "+_vm._s(_vm.$strings.productsheet_title_informations)+" ")]):_vm._e(),(_vm.models && _vm.models.length > 0)?_c('button',{class:[{ active: _vm.tab === 3 }],attrs:{"type":"button"},on:{"click":function($event){_vm.tab = 3}}},[_vm._v(" "+_vm._s(_vm.$strings.productsheet_title_models)+" ")]):_vm._e()],1),(_vm.tab === 0)?_c('div',{staticClass:"tabs-advantages"},[(_vm.product.avantage_media)?_c('div',{key:_vm.videoKey,staticClass:"advantages-video"},[_c('div',{staticClass:"video-player"},[(
                  _vm.product.avantage_media.file.substring(
                    _vm.product.avantage_media.file.length - 3
                  ) === 'mp4'
                )?_c('Video',{attrs:{"src":_vm.product.avantage_media.file}}):_c('img',{attrs:{"src":_vm.product.avantage_media.file,"alt":""}})],1)]):_vm._e(),_c('div',{staticClass:"advantages-content cbo-cms"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.product.avantages)}})])]):_vm._e(),(_vm.tab === 1)?_c('div',{staticClass:"tabs-domains"},[_c('carousel',{staticClass:"domains-slider",attrs:{"centerMode":"","per-page-custom":[
              [0, 1],
              [768, 2],
              [1024, 4]
            ],"pagination-enabled":false}},_vm._l((_vm.markets),function(market,index){return _c('slide',{key:'market_' + index},[_c('div',{staticClass:"slider-el"},[_c('div',{staticClass:"el-icon"},[_c('img',{attrs:{"src":market.logo,"alt":""}})]),_c('div',{staticClass:"el-title"},[_vm._v(" "+_vm._s(market.name)+" ")]),_c('div',{staticClass:"el-button"},[_c('Button',{attrs:{"color":"accent","to":{ name: 'processes', params: { market: market.id } }}},[_vm._v(" "+_vm._s(_vm.$strings.productsheet_domain_button_label)+" ")])],1)])])}),1)],1):_vm._e(),(_vm.tab === 2)?_c('div',{staticClass:"tabs-informations",domProps:{"innerHTML":_vm._s(_vm.product.caract_tech)}}):_vm._e(),(_vm.tab === 3)?_c('div',{staticClass:"tabs-models"},[(_vm.models.length > 0)?_c('v-row',{staticClass:"models-list"},[_c('v-col',_vm._l((_vm.models),function(model,index){return _c('div',{key:'model_' + index},[(index % 2 === 0)?_c('div',{class:[
                    'list-el',
                    { 'el--active': _vm.activeModels.indexOf(index) !== -1 }
                  ]},[_c('button',{staticClass:"el-title",attrs:{"type":"button"},on:{"click":function($event){return _vm.switchModel(index)}}},[_vm._v(" "+_vm._s(model.titre)+" "),_c('div',{staticClass:"title-icon"},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" $vuetify.icons.values.angle ")])],1)]),_c('div',{staticClass:"el-inner"},[(model.image)?_c('div',{staticClass:"el-picture cbo-picture-cover"},[_c('img',{attrs:{"src":model.image.file,"alt":""}})]):_vm._e(),_c('div',{staticClass:"el-description cbo-cms",domProps:{"innerHTML":_vm._s(model.description)}})])]):_vm._e()])}),0),_c('v-col',_vm._l((_vm.models),function(model,index){return _c('div',{key:'model_' + index},[(index % 2 !== 0)?_c('div',{class:[
                    'list-el',
                    { 'el--active': _vm.activeModels.indexOf(index) !== -1 }
                  ]},[_c('button',{staticClass:"el-title",attrs:{"type":"button"},on:{"click":function($event){return _vm.switchModel(index)}}},[_vm._v(" "+_vm._s(model.titre)+" "),_c('div',{staticClass:"title-icon"},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" $vuetify.icons.values.angle ")])],1)]),_c('div',{staticClass:"el-inner"},[(model.image)?_c('div',{staticClass:"el-picture cbo-picture-cover"},[_c('img',{attrs:{"src":model.image.file,"alt":""}})]):_vm._e(),_c('div',{staticClass:"el-description cbo-cms",domProps:{"innerHTML":_vm._s(model.description)}})])]):_vm._e()])}),0)],1):_vm._e()],1):_vm._e()])]),_c('Container',{staticClass:"product-project",attrs:{"primary":"","noRadius":""}},[_c('div',{staticClass:"project-inner"},[_c('div',{staticClass:"project-title",domProps:{"innerHTML":_vm._s(_vm.$strings.productsheet_project_title)}}),_c('div',{staticClass:"project-button"},[_c('Button',{attrs:{"href":_vm.$strings.productsheet_project_button_href,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$strings.productsheet_project_button_label)+" ")])],1)])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }